(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    (function( window, undefined$1 ) {
        kendo.cultures["de-DE"] = {
            name: "de-DE",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ".",
                ".": ",",
                groupSize: [3],
                percent: {
                    pattern: ["-n %","n %"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "Euro",
                    abbr: "EUR",
                    pattern: ["-n $","n $"],
                    decimals: 2,
                    ",": ".",
                    ".": ",",
                    groupSize: [3],
                    symbol: "€"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["Sonntag","Montag","Dienstag","Mittwoch","Donnerstag","Freitag","Samstag"],
                        namesAbbr: ["So","Mo","Di","Mi","Do","Fr","Sa"],
                        namesShort: ["So","Mo","Di","Mi","Do","Fr","Sa"]
                    },
                    months: {
                        names: ["Januar","Februar","März","April","Mai","Juni","Juli","August","September","Oktober","November","Dezember"],
                        namesAbbr: ["Jan","Feb","Mrz","Apr","Mai","Jun","Jul","Aug","Sep","Okt","Nov","Dez"]
                    },
                    AM: [""],
                    PM: [""],
                    patterns: {
                        d: "dd.MM.yyyy",
                        D: "dddd, d. MMMM yyyy",
                        F: "dddd, d. MMMM yyyy HH:mm:ss",
                        g: "dd.MM.yyyy HH:mm",
                        G: "dd.MM.yyyy HH:mm:ss",
                        m: "d. MMMM",
                        M: "d. MMMM",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "HH:mm",
                        T: "HH:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "MMMM yyyy",
                        Y: "MMMM yyyy"
                    },
                    "/": ".",
                    ":": ":",
                    firstDay: 1
                }
            }
        };
    })();

}));
